import { ConfigureUI } from '@/configure/ConfigureUI';

// Imports the SVG content
import svg from '@/assets/icons/exclamation.svg?raw';
import { ConfigureAttribute } from '@/configure/model/ConfigureAttribute';
import { MESSAGING } from '@/constants';

const PRODUCT_MESSAGE_SELECTOR = '.configure-product-messages-container';
const BUTTON_CLASS = 'fc-button';

/**
 * Adds the product-level messages if defined
 * @param configure instance of ConfigureUI
 */
export function addProductMessage(configure: ConfigureUI): void {
  // Gets DOM element. If not available throw error
  const elem: HTMLElement | null = configure.dom.querySelector(PRODUCT_MESSAGE_SELECTOR);
  if (!elem) {
    throw Error(`Product message DOM element (${PRODUCT_MESSAGE_SELECTOR}) not found. Did you forget to add it?`);
  }

  // Get the product messages loaded from Admin Tools
  const messages = getProductMessages(configure);

  // If there is no message, do nothing
  if (messages.length === 0) return;

  // Add the messages DOM including the text
  elem.append(...messages.map(createMessageDiv));
}

/**
 * Finds the product-level messages (0 or more) using the description and availability facets.
 * There could be no messages or many (description and multiple facet values).
 * Normally 0 or 1 message will be defined.
 */
function getProductMessages(configure: ConfigureUI): string[] {
  const messages: string[] = [];

  // If the product contains a description, it will be the first message shown
  const message = configure.getProduct()?.description;
  if (message) messages.push(message);

  // Look for the availability facet and add all messages found
  const facets = configure.getProductFacetsDetails();
  const msgFacet = facets[MESSAGING.PRODUCT_FACET_NAME];
  if (msgFacet?.values) {
    msgFacet.values.forEach((value) => messages.push(value.description));
  }

  return messages;
}

/**
 * Creates the panel for the specified message
 */
function createMessageDiv(message: string) {
  const msgElem = document.createElement('div');
  msgElem.classList.add('configure-product-message');
  msgElem.innerHTML = generateHTML(message);

  /** Close button handler, removes the message */
  function btnClickHandler() {
    msgElem.remove();
  }

  // Adds the click handler
  msgElem.querySelector(`.${BUTTON_CLASS}`)?.addEventListener('click', btnClickHandler, { once: true });

  return msgElem;
}

/**
 * Adds the attibute and value-level messages if they exist.
 *
 * - If only one exists, it adds that one.
 * - If both exist, they are both shown (the attribute message first)
 * - If none exist, it adds nothing.
 * @param configure instance of ConfigureUI
 */
export function addAttributeAndValueMessages(configure: ConfigureUI): void {
  // Get Product
  const product = configure.getProduct();

  // If it's not available, do nothing
  if (!product) return;

  // Register hook to add content after the attribute name but before the values (eg. swatches)
  configure.registerHookWithOpts({
    hook: 'component.attributeSelector.beforeHtml',
    // Messages must come after the "custom attribute title"
    order: 'last',
    handler(ca) {
      // Get the attribute-level message or an empty string
      const attributeMessage = ca.description
        ? `<div class="ca-message-text" data-auto-id="ca-message-${ca.alias}" data-testid="ca-message-${ca.alias}">${ca.description}</div>`
        : '';

      // Get the value-level message or an empty string
      const avMessage = getValueMessage(ca);
      const valueMessage = avMessage
        ? `<div class="ca-message-text" data-auto-id="av-message-${ca.alias}" data-testid="av-message-${ca.alias}">${avMessage}</div>`
        : '';

      // Add the HTML for the message
      return attributeMessage + valueMessage;
    }
  });
}

/**
 * Gets the value-level message for the specified attribute.
 *
 * @param ca attribute instance
 * @returns the value-level message or `null` if it doesn't exist
 */
function getValueMessage(ca: ConfigureAttribute) {
  // Get the selected value for that attribute
  const selectedValue = ca.values.find((av) => av.selected);
  // If there's no selected value, return null
  if (!selectedValue) return null;

  // Find the ValueUsage associated with that value and attribute
  const vu = ca.valueUsages.find((v) => v.valueId === selectedValue.id);
  // If there's no value usage, return null
  if (!vu) return null;

  // TODO: Change this to use Metadata.ts methods when merged from "location-conflict" branch
  // If the value usage has a metadata entry with the right key, use its value, otherwise return null
  const metadataEntry = vu.metadata?.find(({ key }) => key === MESSAGING.VU_METADATA_KEY);

  // If the message entry is defined, use it, otherwise return null
  return metadataEntry?.value ?? null;
}

/**
 * Generates the HTML for the product-level message
 * @param text message to show
 */
function generateHTML(text: string): string {
  return `
    <div class="fc-product-message-avatar">
      ${svg}
    </div>
    <span class="fc-product-message-text" data-auto-id="product-message" data-testid="product-message">${text}</span>
    <div aria-label="Close" class="fc-button-pair fc-outline-target" role="button" tabindex="0">
      <div class="${BUTTON_CLASS}">
        <span class="fc-adi-icon-close"></span>
      </div>
    </div>
  `;
}
