import loader from '@/components/loader';
import { ConfigureUI } from '@/configure/ConfigureUI';
import { DialogEventBus } from '@/configure/components/dialog';
import { SnapshotSettings } from '@/configure/webgl';
import { SNAPSHOTS } from '@/constants';
import { t } from '@/i18n';
import {
  downloadSnapshots,
  getCameraLocalizedName,
  getCurrentViewName,
  getProductSnapshotCameras,
  trackSnapshotAnalytics
} from './snapshots-services';

/** Layer number, used to prevent conflicts with "Start Over Dialog", which uses layer 0 */
const DIALOG_LAYER = 1;

/** Name of the form input */
const FORM_INPUT_NAME_VIEWS = 'snapshots';
const FORM_INPUT_NAME_FORMAT = 'format';
const FORM_INPUT_NAME_SIZE = 'size';

const BTN_GENERATE_CLASS = 'fc-adi-snapshots-btn-generate';
const ITEM_CLASS = 'fc-adi-snapshots-item';
const STATUS_CLASS = 'fc-adi-snapshots-status';
const SELECTED_CLASS = 'selected';

export async function openSnapshotsDialog(configure: ConfigureUI): Promise<void> {
  // Create UUID to avoid conflicts
  const id = 'fc-adi-snapshots-modal-' + Date.now();

  // Create the dialog
  const dialog = await configure.createDialog({
    type: 'html',
    innerHTML: Modal(id, [getCurrentViewName(), ...(await getProductSnapshotCameras(configure))]),
    customClassName: 'fc-adi-snapshots',
    closeOnBlur: true,
    showClose: true,
    width: 600,
    layer: DIALOG_LAYER
  });

  trackSnapshotAnalytics(configure, 'downloadImagesDialog');

  void initDOM(id, configure, dialog);
}

async function initDOM(id: string, configure: ConfigureUI, dialog: DialogEventBus) {
  const container = document.getElementById(id);
  if (!container) return;

  const btnContinue = container.querySelector<HTMLElement>(`.${BTN_GENERATE_CLASS}`);
  const form = container.querySelector<HTMLFormElement>(`#${id} form`)!;
  const selectAllCheck = container.querySelector<HTMLInputElement>('.fc-adi-snapshots-select-all input');

  // Should not happen
  if (!btnContinue || !form || !selectAllCheck) {
    console.warn('Error while creating the "Generate Snapshots" Dialog: Some DOM elements were not found');
    return;
  }

  const webgl = await configure.getConfigureWebgl();

  const viewItems = container.querySelectorAll<HTMLElement>(`.${ITEM_CLASS}`);

  // Init the views
  viewItems.forEach(initViewDOM);

  // Select All change handler
  selectAllCheck.parentElement?.addEventListener('click', (e) => {
    // If the click was made outside the checkbox, change the state
    if (e.target !== selectAllCheck) selectAllCheck.checked = !selectAllCheck.checked;
    viewItems.forEach((view) => toggleView(view, selectAllCheck.checked));
  });

  /** Inits the logic for each View Component */
  async function initViewDOM(view: HTMLElement) {
    // Load the view thumbnail
    void loadViewImage(view);

    // View click handler
    view.addEventListener('click', () => toggleView(view, !view.classList.contains(SELECTED_CLASS)));
  }

  function toggleView(view: HTMLElement, newValue: boolean) {
    // Change the state of the checkbox and the border
    view.classList.toggle(SELECTED_CLASS, newValue);
    view.querySelector('input')?.toggleAttribute('checked', newValue);

    // Enable "Generate" button only if there's at least one selected view
    const hasSelected = container?.querySelector(`.${SELECTED_CLASS}`);
    btnContinue?.toggleAttribute('disabled', !hasSelected);
    btnContinue?.classList.toggle('fc-disabled', !hasSelected);
  }

  /**
   * Loads the view thumbnail
   */
  async function loadViewImage(view: HTMLElement) {
    const img = view.querySelector('img');
    if (!img) return;
    let cameraName = view.dataset['view'];
    try {
      view.classList.add('loading');

      // If the cameraName is "Current", send undefined to webgl.
      if (cameraName === getCurrentViewName()) cameraName = undefined;
      img.src = await webgl.getSnapshot({ cameraName, width: SNAPSHOTS.THUMBNAIL_SIZE });
    } catch {
      console.warn('Error loading view %s, removing it', cameraName);
      view.remove();
    } finally {
      view.classList.remove('loading');
    }
  }

  /** Form submit logic */
  form.addEventListener('submit', async function (e) {
    e.preventDefault();
    if (e.submitter && (e.submitter as HTMLButtonElement).value === 'generate') {
      // If generate was clicked, download the snapshots
      await generateAndDownload(configure, container, form);
    }
    dialog.trigger('dialog:closeRequest', DIALOG_LAYER);
  });
}

async function generateAndDownload(configure: ConfigureUI, container: HTMLElement, form: HTMLFormElement) {
  const formData = new FormData(form);
  // Get the lists of selected views
  const selectedValue = formData.getAll(FORM_INPUT_NAME_VIEWS) as string[];
  if (selectedValue?.length === 0) return;

  const total = selectedValue.length;
  const format = (formData.get(FORM_INPUT_NAME_FORMAT) as SnapshotSettings['format']) ?? SNAPSHOTS.DEFAULT_FORMAT;
  const sizeValue = formData.get(FORM_INPUT_NAME_SIZE)?.toString();
  const size = sizeValue ? parseInt(sizeValue) : SNAPSHOTS.DEFAULT_SIZE;

  // If the current view is selected, remove it from the list and add the "includeCurrent" flag
  const currentIndex = selectedValue.indexOf(getCurrentViewName());
  const includeCurrent = currentIndex >= 0;
  if (includeCurrent) selectedValue.splice(currentIndex, 1);

  container.classList.add('generating');
  const statusLabel = container.querySelector(`.${STATUS_CLASS}`);
  const instructionsLabel = container.querySelector('.fc-adi-snapshots-light-text');
  if (statusLabel) statusLabel.innerHTML = `0/${total} (0%)`;
  if (instructionsLabel) instructionsLabel.innerHTML = t('sn_dialog_loading');

  try {
    await downloadSnapshots(configure, {
      width: size,
      format,
      includeCurrent,
      views: selectedValue.length === 0 ? undefined : selectedValue,
      onProgress: (progress) => {
        if (statusLabel) statusLabel.innerHTML = `${progress.completed}/${progress.total} (${progress.porcentage}%)`;
      }
    });
  } catch {
    container.classList.remove('generating');
    if (instructionsLabel) instructionsLabel.innerHTML = t('sn_dialog_instructions');
  }
}

// - - - - - - - - - - - - - - - - - - - - - - - COMPONENTS - - - - - - - - - - - - - - - - - - - - - - - - -

/**
 * Modal displayed to let the user generate and download snapshots
 */
const Modal = (id: string, cameras: string[]) => /*html*/ `
  <div id="${id}">
    <div class="fc-adi-snapshots-title-wrapper">
      <h1 class="fc-adi-snapshots-title">
        ${t('sn_dialog_title')}
      </h1>
    </div>
    <p class="fc-adi-snapshots-light-text">
      ${t('sn_dialog_instructions')}
    </p>
    <div class="fc-adi-snapshots-loader">
      ${loader.Big}
      <h2 class="${STATUS_CLASS}"></h2>
    </div>
    <form>
      <div class="fc-adi-snapshots-select-all">
        <input type="checkbox" name="select-all"/>
        <label for="select-all">${t('sn_dialog_select_all')}</label>
      </div>
      <div class="fc-adi-snapshots-views">
        ${cameras.map((c) => View(c)).join('')}
        </div>
        <div class="fc-adi-snapshots-footer">
          ${Settings()}
        </div>
        <div class="fc-adi-snapshots-buttons">
          <button type="submit" class="fc-adi-snapshots-btn-cancel" value="cancel" data-auto-id="btn-snapshots-cancel" data-testid="btn-snapshots-cancel">
            ${t('cancel')}
          <button type="submit" class="${BTN_GENERATE_CLASS} fc-disabled" disabled value="generate" data-auto-id="btn-snapshots-generate" data-testid="btn-snapshots-generate">
            ${t('sn_dialog_accept')}
        </div>
      </div>
    </form>
  </div>
`;

/**
 * Component to display snapshot settings
 */
const Settings = () => /*html*/ `
  <div class="fc-adi-snapshots-settings">
    <div class="fc-adi-snapshots-settings-field">
      <h4 class="fc-adi-snapshots-settings-title">${t('sn_dialog_settings_size')}</h4>
      <select name="${FORM_INPUT_NAME_SIZE}" data-auto-id="select-snapshots-size" data-testid="select-snapshots-size">
        ${SNAPSHOTS.SIZES.map(
          (size) =>
            `<option value="${size}"${SNAPSHOTS.DEFAULT_SIZE === size ? ' selected' : ''}>${size}x${size}</option>`
        )}
      </select>
    </div>
    <div class="fc-adi-snapshots-settings-field">
      <h4 class="fc-adi-snapshots-settings-title">${t('sn_dialog_settings_format')}</h4>
      <select name="${FORM_INPUT_NAME_FORMAT}" data-auto-id="select-snapshots-format" data-testid="select-snapshots-format">
        ${SNAPSHOTS.FORMATS.map(
          (format) =>
            `<option value="${format}"${
              SNAPSHOTS.DEFAULT_FORMAT === format ? ' selected' : ''
            }>${format.toUpperCase()}</option>`
        )}
      </select>
    </div>
  </div>
`;

/**
 * Component to display each view
 */
const View = (cameraName: string) => /*html*/ `
  <div class="${ITEM_CLASS}" data-view="${cameraName}">
  <div class="fc-adi-snapshots-item-overlay">
    <input type="checkbox" name="${FORM_INPUT_NAME_VIEWS}" value="${cameraName}" data-auto-id="checkbox-snapshots-view-${cameraName}" data-testid="checkbox-snapshots-view-${cameraName}"/>
    <label for="views">
    ${getCameraLocalizedName(cameraName)}
    </label>
  </div>
    <div class="fc-adi-snapshots-item-image">
        <img />
        ${loader.Small}
    </div>
  </div>
`;
