import { ConfigureUI } from './configure/ConfigureUI';
import { t } from './i18n';
import { getElement } from './utils/browser';

/**
 * Receives el container element or selector and renders the required HTML for ConfigureUI
 * @param el HTMLElement or string selector
 */
export function createMainLayout(el: string | HTMLElement): void {
  getElement(el).innerHTML = MainLayout();
}

/**
 * Since i18n is not available when creating the layout, we need to modify localizable texts after configure is loaded
 */
export function localizeLayout(configure: ConfigureUI): void {
  const moreActionsLabelWrapper = configure.dom.querySelector('.fc-ly-more-actions-label-wrapper');
  if (moreActionsLabelWrapper) moreActionsLabelWrapper.innerHTML = t('ly_more_actions');

  const rotateZoomLabelWrapper = configure.dom.querySelector('.fc-ly-rotate-zoom-label-wrapper');
  if (rotateZoomLabelWrapper) rotateZoomLabelWrapper.innerHTML = t('ly_rotate_zoom');

  const downloadSnapshotsLabelWrapper = configure.dom.querySelector('.fc-ly-download-snapshots-label-wrapper');
  if (downloadSnapshotsLabelWrapper) downloadSnapshotsLabelWrapper.innerHTML = t('ly_download_snapshots');

  const shareDesignTitleWrapper = configure.dom.querySelector('.fc-ly-share-design-title');
  if (shareDesignTitleWrapper) shareDesignTitleWrapper.innerHTML = t('ly_share_design');
}

function MainLayout() {
  return /* html */ `
    <div class="configure-container-wrap">
      <div class="configure-container-left configure-display-container">
        <!-- LEFT -->
        <div class="configure-product-display-wrapper">
          <div class="configure-product-display"></div>
          <!--<div class="configure-thumbnails-display-container">
            <div class="configure-thumbnails-display"></div>
          </div>-->
          <div class="configure-full-screen-btn fc-outline-target" tabindex="0"></div>
          <div class="configure-ar-btn"></div>
          <div class="webgl-loader-wrapper"></div>

          <div class="configure-dropdown-wrap">
            <div class="configure-rotate-n-zoom-container">
              <div class="configure-rotate-n-zoom fc-ly-rotate-zoom-label-wrapper">
              </div>
            </div>
            <ul
              class="configure-dropdown-menu"
              role="menubar"
              aria-controls="more-actions"
              data-fc-dropdown-container>
              <li class="configure-dropdown-menuitem" role="menuitem">
                <button
                  aria-haspopup="true"
                  aria-expanded="false"
                  tabindex="0"
                  data-fc-dropdown-trigger
                  data-level="0"
                  class="fc-outline-target"
                  data-auto-id="btn-more-actions" data-testid="btn-more-actions">
                  <span class="fc-ly-more-actions-label-wrapper">
                  </span>
                </button>
                <ul class="configure-dropdown-submenu" role="menu" aria-hidden="true" data-level="0">
                  <li
                    class="configure-dropdown-menuitem configure-snapshots"
                    role="menuitem"
                    aria-controls="more-actions"
                    data-auto-id="more-actions-item-scratchpad" data-testid="more-actions-item-scratchpad"></li>
                  <li
                    class="configure-dropdown-menuitem configure-reset-recipe-container"
                    role="menuitem"
                    aria-controls="more-actions"
                    data-auto-id="more-actions-item-reset-recipe" data-testid="more-actions-item-reset-recipe"></li>
                  <!--<li
                    class="configure-dropdown-menuitem configure-randomize-recipe-button"
                    role="menuitem"
                    aria-controls="more-actions"></li>-->
                  <!--<li
                    class="configure-dropdown-menuitem"
                    role="menuitem" a
                    ria-controls="more-actions">
                    <button
                      class="configure-share-button-container fc-outline-target"
                      aria-expanded="false"
                      data-level="1"
                      tabindex="0"
                      data-fc-dropdown-trigger></button>
                    <ul class="configure-dropdown-submenu share-submenu" role="menu" aria-hidden="true" data-level="1">
                      <li role="menuitem" aria-controls="more-actions">
                        <div class="configure-share-title fc-ly-share-design-title">
                        </div>
                        <div class="configure-share-container">
                        </div>
                      </li>
                    </ul>
                  </li>-->
                  <!--<li
                    class="configure-dropdown-menuitem configure-print-button"
                    role="menuitem"
                    aria-controls="more-actions"></li>-->
                </ul>
              </li>
            </ul>
            <div class="configure-download-snapshots-container">
              <button
                  aria-haspopup="true"
                  aria-expanded="false"
                  tabindex="0"
                  data-fc-dropdown-trigger
                  data-level="0"
                  class="fc-outline-target configure-download-snapshots"
                  data-auto-id="btn-snapshots" data-testid="btn-snapshots">
                  <span class="fc-ly-download-snapshots-label-wrapper"></span>
                </button>
            </div>
            <button class="toggle-thumbs">
              <i class="icon icon-eye"></i>
            </button>
          </div>
          <div class="configure-out-reset-recipe-container"></div>
        </div>
      </div>
      <div class="configure-container-right">
        <!-- RIGHT -->
        <div class="info-container">
          <div class="configure-product-info"></div>
          <div class="configure-product-messages-container"></div>
        </div>
        <div class="configure-attributes-wrapper">
          <div class="configure-nav-md"><div class="configure-desktop-only"></div></div>
          <div class="configure-nav-lg"></div>
          <!-- <div class="configure-size-quantity-container">
            <div class="configure-size-container"></div>
          </div> -->
        </div>
        <!-- The styles of this component is ./cart/all.css-->
        <!-- <div class="configure-add-to-cart-container">
          <div class="configure-quantity-container"></div>
          <div class="configure-add-to-cart-button"></div>
        </div>-->
      </div>
    </div>
    <div class="fc-loading"></div>
  `;
}
