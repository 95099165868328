import { ConfigureUI } from '@/configure/ConfigureUI';
import { ConfigureAttribute } from '@/configure/model/ConfigureAttribute';
import { AttributeValuePair } from '@/configure/types/configureui-types';
import { ConfigureEventDispatcher } from '@/ConfigureEventDispatcher';
import { ALIAS_SUFFIXES } from '@/constants';
import { isTrueAV } from '@/features/boolean-avs';
import { waitForDom } from '@/utils/dom';
import { isToggleCA } from '.';
import { textFieldValidate } from './text-field-validation';
// - - - - - - - - - - - - - - - - - - - - - - CONSTANTS - - - - - - - - - - - - - - - - - - - - - - - - -

/** CSS Classes */
const CONTAINER_CLASS = 'fc-text-field-custom-html';
const TEXT_FIELD_INPUT_CONTAINER_CLASS = 'fc-text-field-input-container';
const TEXT_FIELD_PLACEHOLDER_CLASS = 'fc-text-field-placeholder';
const TEXT_FIELD_INPUT_DATA_ATTRIBUTE = 'fc-text-field-input';
const TEXT_FIELD_COUNTER_CLASS = 'fc-text-field-counter';
const TEXT_FIELD_CLEAR_BUTTON_CLASS = 'fc-text-field-clear-button';

// - - - - - - - - - - - - - - - - - - - - - - - MAIN - - - - - - - - - - - - - - - - - - - - - - - - -

export function implementTextField(dispatcher: ConfigureEventDispatcher, configure: ConfigureUI): void {
  // CA focus on accordion or pager
  configure.on('ca:focus', () => {
    handleFocusAndResize(configure);
  });

  // Recipe load or change
  configure.on('recipe:loaded', (changes) => handleRecipeChange(configure, changes));
  configure.on('recipe:change', (changes) => handleRecipeChange(configure, changes));

  configure.on('mediaQuery:change', () => {
    handleFocusAndResize(configure);
  });

  // Adds the hook to render the custom UI in the accordion
  configure.registerHook('component.attributeSelector.afterHtml', renderTextFieldUI);

  // Add Handler for the Text Field inputs keypress events
  configure.dom.addEventListenerByData(
    `[data-custom-input='${TEXT_FIELD_INPUT_DATA_ATTRIBUTE}']`,
    'keydown',
    'ca',
    async (_, __, container) => {
      await waitForDom();
      updateInputAppearance(container);
    }
  );

  // Add handler for input changes, to determine if text validation is running
  configure.dom.addEventListenerByData(
    `[data-custom-input='${TEXT_FIELD_INPUT_DATA_ATTRIBUTE}']`,
    'input',
    'ca',
    async (_, __, container) => textFieldValidate(dispatcher, configure, container)
  );

  // Add Handler for the Text Field Clear Button click events
  configure.dom.addEventListenerByData(`.${TEXT_FIELD_CLEAR_BUTTON_CLASS}`, 'click', 'ca', (e, alias, container) => {
    e.preventDefault();

    const ca = configure.getAttribute({ alias });
    if (ca) configure.resetAttribute(ca);

    updateInputAppearance(container);
  });
}

// - - - - - - - - - - - - - - - - - - - - - - - FUNCTIONS - - - - - - - - - - - - - - - - - - - - - - - - -

/**
 * Handles the focus on accordion or resize of the application.
 * Checks if any of the changes include a toggle CA, if is turned on check the sub attributes to move the text ones to the custom UI
 */
function handleFocusAndResize(configure: ConfigureUI): void {
  const activeCA = configure.getActiveCA();

  if (activeCA) {
    checkSubAttributesAndMoveOriginalTextField(configure, activeCA);
  }
}

/**
 * Handles the recipe load or change.
 */
function handleRecipeChange(configure: ConfigureUI, modifications: AttributeValuePair[]) {
  modifications.forEach((item) => {
    const { ca, av } = configure.getFullRecipeItem(item);

    // Checks if any of the changes includes a toggle CA, if is turned on check the sub attributes to move the text ones to the custom UI
    if (isToggleCA(ca) && isTrueAV(av)) {
      checkSubAttributesAndMoveOriginalTextField(configure, ca);

      // Checks if any of the changes includes a text field. It is does, the char counter and placeholder must be updated
    } else if (isTextCA(ca)) {
      void updateInputCounterOnChange(configure, ca);
    }
  });
}

/**
 * Determines whether the CA is a toggle
 */
function isTextCA(ca: ConfigureAttribute) {
  return ca.selectorType === 'text' && ca.alias.endsWith(ALIAS_SUFFIXES.TEXT);
}

/**
 * ConfigureUI Hook.
 *
 * Only renders custom HTML if the CA alias is ends with 'text'
 */
function renderTextFieldUI(ca: ConfigureAttribute): string | undefined {
  return ca.selectorType === 'text' ? TextField(ca) : undefined;
}

/**
 * Updates the text field's char counter and placeholder when the value changes.
 */
async function updateInputCounterOnChange(configure: ConfigureUI, ca: ConfigureAttribute) {
  // Wait for the DOM to update to the new value before making the changes
  await waitForDom();
  const hook: HTMLElement | null = configure.dom.querySelector(`.${TEXT_FIELD_INPUT_CONTAINER_CLASS}-${ca.id}`);
  if (hook?.parentElement) updateInputAppearance(hook.parentElement);
}

/**
 * Updates the Text Field Character Counter UI
 * @param hookContainer Element with 'CONTAINER_CLASS' class
 */
function updateInputAppearance(hookContainer: HTMLElement): void {
  const input: HTMLInputElement | null = hookContainer.querySelector(
    `[data-custom-input='${TEXT_FIELD_INPUT_DATA_ATTRIBUTE}']`
  );
  if (!input) return;

  const charCount: number = input.value.length;
  const maxLength: number = +input.getAttribute('maxlength')!;

  setCounterText(hookContainer, charCount, maxLength);
  updatePlaceHolderAppearance(hookContainer, charCount);
}

/**
 * Updates the Text Field Character Counter UI
 * @param hookContainer Element with 'CONTAINER_CLASS' class
 */
function setCounterText(hookContainer: HTMLElement, charCount: number, maxLength: number): void {
  const counter: HTMLSpanElement | null = hookContainer.querySelector(`.${TEXT_FIELD_COUNTER_CLASS}`);
  if (counter) counter.innerText = `${charCount}/${maxLength}`;
}

/**
 * Updates the Text Field Placeholder style according to whether the input is empty or not
 * @param hookContainer Element with 'CONTAINER_CLASS' class
 * @param input Element with 'TEXT_FIELD_INPUT_CLASS' class
 * @param charCount current input character length
 */
function updatePlaceHolderAppearance(hookContainer: HTMLElement, charCount: number): void {
  const inputContainer = hookContainer.querySelector(`.${TEXT_FIELD_INPUT_CONTAINER_CLASS}`);
  inputContainer?.classList.toggle('with-custom-placeholder', charCount > 0);
}

/**
 * Moves the original Text Field to a Custom UI
 * @param configure Configure UI
 * @param ca Configure Attribute
 */
function checkSubAttributesAndMoveOriginalTextField(configure: ConfigureUI, ca: ConfigureAttribute) {
  const scas = configure.getAttribute({ id: ca.id })?.subAttributes;
  scas?.filter(isTextCA).forEach((sca) => moveOriginalTextField(configure, sca));
}

/**
 * Moves the original Text Field to a Custom UI
 * @param ca Configure Attribute
 */
async function moveOriginalTextField(configure: ConfigureUI, ca: ConfigureAttribute) {
  await waitForDom();

  const input: HTMLInputElement | null = configure.dom.querySelector(`[data-id='${ca.id}'] input`);
  if (!input) return;

  input.setAttribute('placeholder', ca.name);
  input.setAttribute('maxlength', ca.maxLength ? ca.maxLength + '' : '');
  input.dataset['customInput'] = TEXT_FIELD_INPUT_DATA_ATTRIBUTE;
  input.dataset['alias'] = ca.alias;

  const hook = configure.dom.querySelector(`.${TEXT_FIELD_INPUT_CONTAINER_CLASS}-${ca.id}`);
  const inputParent = input.parentElement;

  if (hook && inputParent) {
    hook.append(...Array.from(inputParent.childNodes));
    if (hook.parentElement) updateInputAppearance(hook.parentElement);
  }
}

/**
 * Generates the Text Field Custom UI
 * @param ca Configure Attribute
 * @returns the HTML to display
 */
function TextField(ca: ConfigureAttribute): string {
  return /* html */ `
	<div class="${CONTAINER_CLASS}" data-ca="${ca.alias}">
		<div class="${TEXT_FIELD_INPUT_CONTAINER_CLASS} ${TEXT_FIELD_INPUT_CONTAINER_CLASS}-${ca.id}" data-auto-id='text-field-${ca.alias}' data-testid='text-field-${ca.alias}'>
			<span class="${TEXT_FIELD_PLACEHOLDER_CLASS}">${ca.name}</span>
		</div>
		<span class="${TEXT_FIELD_COUNTER_CLASS}">0/${ca.maxLength}</span>
		<button class="${TEXT_FIELD_CLEAR_BUTTON_CLASS}" tabindex="0">✖</button>
	</div>
	`;
}
