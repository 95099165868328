import { SnapshotSettings } from './configure/webgl';

/**
 * Aliases for the menu groups (nested accordion and pager side menu).
 * The groups here will be shown in the menus, any other group will be ignored.
 *
 * **THE ORDER IS IMPORTANT!** The group name will change according to localization, so the order description and icon
 * will be retrieved by using the index in this array.
 */
export const MENU_GROUPS = ['style', 'personalize'];

/**
 * Pixels per CM
 * All images sizes are expressed in cm, so we use this value to convert it to pixels.
 */
export const PIXELS_PER_CM = 60;

/**
 * ID of the Facet and Facet Values that determine that an AV has a boolean value (true/false)
 */
export const BOOLEAN_AV_FACETS = {
  FACET_ID: 26113,
  TRUE_ID: 72332,
  FALSE_ID: 72333
};

/** CA aliases suffixes used to perform custom logic  */
export const ALIAS_SUFFIXES = {
  TOGGLE: '.toggle',
  TEXT: '.text',
  FONT: '.font',
  COLOR: '.color',
  SIZE: '.size',
  OUTLINE: '.outline',
  OUTLINE_COLOR: '.outline_color',
  ARC: '.arc',
  LOCATION: '.location'
};

export const FONTS = {
  /** URL where the font files are located */
  URL: 'https://cdn-webgl-assets.fluidconfigure.com/webgl-assets/prod/1622/fonts/',

  /** Font files extension */
  EXTENSION: 'ttf'
};

/** Localization constants */
export const LOCALIZATION = {
  /** Available locales */
  locales: [
    'cs_CZ',
    'da_DK',
    'de_DE',
    'en_GB',
    'en_US',
    'es_ES',
    'es_LA',
    'fr_CA',
    'fr_FR',
    'it_IT',
    'ja_JP',
    'nl_NL',
    'pl_PL',
    'ru_RU',
    'sk_SK',
    'sv_SE'
  ],
  /** Global fallback if no locale is specified or the locale's language has no default */
  defaultLocale: 'en_GB',
  /** Fallback for each language */
  defaults: {
    de: 'de_DE',
    en: 'en_GB',
    es: 'es_ES',
    fr: 'fr_FR'
  } as Record<string, string>
};

/** Messaging constants */
export const MESSAGING = {
  /** Name of the facet that contains the availability messages */
  PRODUCT_FACET_NAME: 'Product Availability Message',

  /** Key of the Metadata entry containing the localized message */
  VU_METADATA_KEY: 'message'
};

/** Validation Constants */
export const VALIDATION = {
  // Suffixes of CAs that can have no values and still be valid
  IGNORED_CA_SUFFIXES: [ALIAS_SUFFIXES.ARC, ALIAS_SUFFIXES.OUTLINE, '.decoration']
};

/** Generate/Download Snapshots Constants */
export const SNAPSHOTS = {
  SIZES: [256, 512, 1024, 2048],
  FORMATS: ['png', 'jpeg'],
  DEFAULT_SIZE: 1024,
  DEFAULT_FORMAT: 'png' as SnapshotSettings['format'],
  THUMBNAIL_SIZE: 100,
  VIEWS: [
    'Current',
    'Front',
    'Back',
    'Right',
    'Left',
    'Hero',
    'Default',
    'Catalog',
    'Side',
    'LeftLeg',
    'RightLeg',
    'Logo',
    'Chest',
    'Stripes'
  ]
};

/** Compliance dialog constants */
export const COMPLIANCE = {
  /** Name of the facet that contains the additional message for the product */
  ADDITIONAL_MESSAGE_FACET: 'Compliance Additional Message'
};
