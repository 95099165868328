import { ConfigureAdidas } from '@/ConfigureAdidas';
import { setDisplayLoaderIndeterminate } from '@/components/webgl-display-loader';
import { ConfigureUI } from '@/configure/ConfigureUI';
import { RecipeDocument } from '@/configure/model/RecipeDocument';
import { readFileFromUrl } from '@/configure/utils/file';
import { implementImageGalleryResize } from './image-gallery-size';
import {
  AdidasImagePayload,
  AdidasImagePayloadForUI,
  AdidasRecipeCustomData,
  ImageEventPayload
} from './image-gallery-types';
import { implementImageGalleryUI, updateGalleryForCA } from './image-gallery-ui';

// Re export the Payload interface
export { type AdidasImagePayload, type ImageEventPayload };

// Adidas Payloads use by this instance of ConfigureUI
// NOTE: this doesn't support 2 instances at the same time
let adidasPayloads: Map<string, AdidasImagePayloadForUI>;

/**
 * Implements the Image Gallery Feature by adding the custom UI and events handlers
 *
 * @param wrapper event dispatcher
 * @param configure ConfigureUI Instance
 */
export function implementImageGallery(wrapper: ConfigureAdidas, configure: ConfigureUI): void {
  // Create a new Map, so the previous instance's payloads won't leak
  adidasPayloads = new Map();

  const helpers = implementImageGalleryResize(wrapper, configure, adidasPayloads);
  implementImageGalleryUI(wrapper, configure, adidasPayloads, helpers);

  // Adds the custom payload to the recipe everytime it's saved
  configure.registerHook('method.beforeSaveRecipe', function (_options, callback) {
    const recipePayload: Record<string, AdidasImagePayload> = {};
    // Create a copy of the recipe payload and remove the "loading" property (only used in the UI)
    adidasPayloads.forEach((payload, alias) => {
      const value: AdidasImagePayload & { loading?: boolean } = JSON.parse(JSON.stringify(payload));
      delete value.loading;
      recipePayload[alias] = value;
    });

    // Add the payload to the recipe
    callback(null, { imageGallery: recipePayload });
  });

  // On recipe load, the custom payload is extracted to get additional info (filename, adidas id, etc)
  configure.on('recipe:loaded', (_changes, recipeDoc: RecipeDocument<AdidasRecipeCustomData>) => {
    if (recipeDoc.custom?.imageGallery) {
      Object.entries(recipeDoc.custom.imageGallery).forEach(([key, value]) => {
        adidasPayloads.set(key, value);

        // Update the UI after loading adidasPayloads (sometimes this event is called after the hook)
        void updateGalleryForCA(wrapper, configure, key, value);
      });
    }
  });

  // Just in case we clear the map when the instance is destroyed
  configure.once('destroy:start', () => {
    console.log('[Image Gallery] Clearing the payloads map');
    adidasPayloads.clear();
  });
}

/**
 * Loads the provided image URL into ConfigureUI.
 *
 * Called by the container application.
 *
 * @param wrapper ConfigureAdidas instance
 * @param configure ConfigureUI instance
 * @param payload image info
 */
export async function loadAndSetUgcImage(
  wrapper: ConfigureAdidas,
  configure: ConfigureUI,
  payload: AdidasImagePayload
): Promise<void> {
  // Reads the image as DataURL
  const dataURI = await readFileFromUrl(payload.url, 'url');
  if (dataURI === null) throw new Error('Error while loading image from ' + payload.url);

  const caAlias = payload.embellishmentData.caAlias;
  adidasPayloads.set(caAlias, payload);

  // Sets the image as the value of the right CA, showing loaders in the meantime
  try {
    updateUI(wrapper, configure, caAlias, true);
    await configure.setUgcImage({ ca: { alias: caAlias }, dataURI });
  } finally {
    // We need to update the accordion here again because it will automatically update when the CA changes BUT NOT when
    // the size changes
    updateUI(wrapper, configure, caAlias, false);
  }
}

/**
 * Updates the UI to show the right thumbnail, filename and,
 * if loading, spinners in both the product display and accordion/pager
 */
function updateUI(wrapper: ConfigureAdidas, configure: ConfigureUI, caAlias: string, loading: boolean) {
  // Get the latest payload associated with the CA
  const payload = adidasPayloads.get(caAlias);
  if (payload) {
    // Update the loading flag and re render
    payload.loading = loading;
    setDisplayLoaderIndeterminate(configure, loading);
    void updateGalleryForCA(wrapper, configure, caAlias, payload);
  }
}
