import { ConfigureUI } from '@/configure/ConfigureUI';
import { ConfigureEventDispatcher } from './ConfigureEventDispatcher';
import { initComponents } from './components';
import { ConfigureAPI } from './configure/extension/webgl-script-types';
import {
  AddToCartInfo,
  ConfigureInitError,
  ConfigureInitParams,
  SaveRecipeResult
} from './configure/types/configureui-types';
import { handleConfigureInitError } from './error-handlers';
import { initCustomFeatures } from './features';
import { AdidasImagePayload, loadAndSetUgcImage } from './features/image-gallery';
import { PerformanceMetrics, addPerformanceMarkerClasses } from './features/performance';
import { openSnapshotsDialog } from './features/snapshots/snapshots-dialog';
import { showValidationDialog, validateRecipe } from './features/validation';
import { initI18n } from './i18n';
import { resolveLocale } from './i18n/locale-selector';
import { createMainLayout } from './layout';
import { buildInfo, handleVersionParameter } from './utils/library-info';

// Add global configure instance for Astound's Product Debugger
declare global {
  interface Window {
    _configure?: ConfigureAPI;
  }
}

/** Customer ID. Replace for each implementation */
const CUSTOMER_ID = buildInfo.customerId;

/**
 * ConfigureUI Implementation Parameters
 */
interface ConfigureImplementationParams extends Omit<ConfigureInitParams, 'customer' | 'id' | 'logEvents'> {
  /**
   * Determines whether the library version should be added.
   *
   * - If it's an HTML Element or a selector, the library version is inserted in that element.
   * - If it's `true`, an element with '.fc-app-version' is created fixed at the bottom right.
   * - Otherwise, no version is shown
   *
   */
  versionLabel?: HTMLElement | string | boolean;

  /**
   * Flag that indicates the performance is being measured.
   * This will remove the initial 3D model animation and log some metrics in the console
   */
  performance?: boolean;

  /**
   * Flag that forces the league rules dialog on init when the product contains a "compliance" CA.
   * Without this flag, the dialog is only shown on blank products. When a recipe is loaded, the dialog is skipped.
   */
  forceLeagueRulesDialog?: boolean;

  /**
   * Flags to indicate which sections to show in the accordion.
   *
   * By default all sections are shown.
   *
   * If any section is disabled, **mobile viewport will not load** and a message will be shown instead asking the user
   * to use a Desktop device.
   */
  menuSections?: {
    style?: boolean;
    personalise?: boolean;
  };

  /**
   * Enables the image gallery feature.
   *
   * By default it is `true` (enabled).
   *
   * If `false`, images may only be added by uploading them in the session.
   */
  imageLibraryEnabled?: boolean;
}

/**
 * ConfigureUI Adidas Implementation class
 */
export class ConfigureAdidas extends ConfigureEventDispatcher {
  #opts: ConfigureImplementationParams;
  #configure: ConfigureUI;
  #performance: PerformanceMetrics;

  /**
   * Creates a new instance of ConfigureUI
   * @param opts configuration parameters
   */
  constructor(opts: ConfigureImplementationParams) {
    super();

    // Init Performance metrics
    this.#performance = new PerformanceMetrics('configure', opts.performance);
    this.#performance.implementationCreate();

    // Default parameter values
    opts.imageLibraryEnabled ??= true;

    this.#opts = opts;
    this.#configure = new ConfigureUI({
      ...opts,
      locale: resolveLocale(this.#opts.locale), // Find the right locale
      customer: CUSTOMER_ID,
      logEvents: false
    });

    // Enable performance metrics on the ConfigureUI instance
    this.#performance.measureConfigureUI(this.#configure);
  }

  /**
   * Returns the initialization parameters
   */
  get params(): ConfigureImplementationParams {
    return Object.freeze(this.#opts);
  }

  /**
   * Initializes ConfigureUI and the implementation
   */
  async start(): Promise<void> {
    // Add performance markers
    this.#performance.implementationStart();
    addPerformanceMarkerClasses(this.#configure);

    if (this.#configure.destroying) return;

    createMainLayout(this.#configure.dom.container);
    try {
      if (this.#opts.versionLabel) {
        handleVersionParameter(
          this.#opts.versionLabel === true ? this.#configure.dom.container : this.#opts.versionLabel
        );
      }

      try {
        // Wait until the ConfigureUI API is loaded, so the implementation can start creating components
        await this.#configure.ready('api');
      } catch (e) {
        // If there's a error loading configure, try to handle it.
        // Invalid or missing recipe are considered recoverable errors, ie. The configurator will still load
        // after showing an error dialog
        await handleConfigureInitError(this.#configure, e as ConfigureInitError);

        // Set the apiReady promise to resolved, as we have handled the error
        this.#configure.markAsReady();
      }

      // Add Configure instance to the global object so our Astound tools can find it
      if (this.#configure) window._configure = this.#configure.getApi();

      if (this.#configure.destroying) return;

      this.#configure.once('display:ready', () => {
        // Notifies parent application that this instance is ready to be destroyed
        this.dispatchEvent(new CustomEvent('destroyable'));

        // Logs timing metrics in the console
        this.#performance.log();
      });

      await this.initImplementation();

      if (this.#configure.destroying) return;

      // This method should resolve its promise only when the product is displayed
      // Only then the user should start interacting with it
      await this.#configure.ready('model');

      this.#performance.implementationReady();
      console.log('[ConfigureAdidas] Finished initializing ConfigureUI and its components');
    } catch (e) {
      // If there's an error that cannot be solved, destroy the Configurator and throw the error to the caller
      await this.destroy();
      throw e;
    }
  }

  /**
   * Returns a promise that resolves when the specified entity is loaded and ready to be used:
   * - `api`: ConfigureUI API is loaded. Components, hooks and listeners may be created.
   * - `dom`: All the implementation components and DOM have been created.
   * - `webgl`: WebGL Display has been initialized and the model will start loading
   * - `model`: Product model is displayed (load progress == 100). User interactions may begin.
   * - `display`: Product model is loaded and its initial animation is done.
   *
   *  ConfigureUI **CANNOT** be destroyed until the display is ready.
   */
  ready(entity: Parameters<ConfigureUI['ready']>[0]): Promise<void> {
    return this.#configure.ready(entity);
  }

  destroy(): Promise<void> {
    this.removeAllEventListeners();
    this.#performance.destroy();
    return this.#configure.destroy();
  }

  /**
   * Initializes the implementation after ConfigureUI is loaded
   */
  private async initImplementation(): Promise<void> {
    this.checkSettings();

    await initI18n(this.#configure);

    if (this.#configure.destroying) return;

    // Wait for components and custom features
    await Promise.all([
      initComponents(this, this.#configure),
      // TODO Check if any custom feature requires the components. For now, they execute in parallel
      initCustomFeatures(this, this.#configure)
    ]);
  }

  private checkSettings() {
    if (!this.#configure.getPreferences().apiKey) console.warn("Customer doesn't have apiKey");
  }

  /** Sets and displays the provided external image in the specified attribute  */
  displayImage(payload: AdidasImagePayload): Promise<void> {
    // Load the provided image into configure
    return loadAndSetUgcImage(this, this.#configure, payload);
  }

  /**
   * Triggers "Add to Cart" on the current recipe:
   *
   * It will validate the recipe and if it's OK, save it and return the recipe ID and URL.
   *
   * If validation fails it will display a dialog with the errors and return them as an array of strings.
   *
   * @returns a promise that will resolve when the recipe is saved with the recipe data or will fail if
   * the validation fails or the save request fails.
   */
  addToCart(): Promise<Pick<AddToCartInfo, 'id' | 'resource'>> {
    const error = validateRecipe(this.#configure);
    if (error) {
      showValidationDialog(this.#configure, error);
      throw error;
    }
    return this.#configure.addToCart().then((data) => ({ id: data.id, resource: data.resource }));
  }

  /**
   * Saves the current recipe in order to be able to share it.
   * @returns a promise that will resolve when the recipe is saved with the recipe data.
   */
  saveToShare(): Promise<SaveRecipeResult> {
    return this.#configure.saveRecipe();
  }

  /**
   * Opens the Snapshots dialog, where the user can select, generate and download snapshots of multiple views.
   *
   * After the user clicks "generate":
   * - If only one view is requested, the image will be downloaded directly.
   * - If more than one view is requested, a zip file containing all the files is downloaded.
   */
  openSnapshotsDialog(): Promise<void> {
    return openSnapshotsDialog(this.#configure);
  }

  /**
   * Allows the LockerRoom app to notify the Configurator when a design has been saved.
   *
   * The Configurator will use YR Analytics to track this event.
   *
   * It is useful to later analyze the drop rate between the addToCart to Save Design action
   *
   * @param payload event payload, including the associated recipe ID
   */
  notifyDesignSaved(payload: { recipeId: number; designName: string }): void {
    const { recipeId, designName } = payload;

    // Only track if the recipe ID is valid
    if (!recipeId) throw new Error('Please include a valid recipe ID to track "Design Saved" event');

    // Track the customization save event in our Analytics
    this.#configure.analytics('customEvent', {
      name: 'designSaved',
      recipeId: recipeId.toString(),
      payload: { design_name: designName }
    });
  }
}
